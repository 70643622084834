import React, { useState } from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Title } from '../components/Title'
import { IntroWithSlider } from '../components/IntroWithSlider'
import { BlogSnippet } from '../components/BlogSnippet'
import { Styled } from '../styles/blog.styles'
import { parseBlogPosts } from '../utils/helpers'
import { graphql } from 'gatsby'

const BlogPage = ({
  data: {
    miscJson: content,
    allMarkdownRemark: { edges },
  },
  location
}) => {
  const [postsNumber, setPostsNumber] = useState(3)

  const posts = parseBlogPosts(edges)
  if (!posts || !Boolean(posts.length)) {
    return (
      <Layout location={location}>
        <SEO title="Blog" description={content.blogSeoDescription} />
        <Styled.Main>
          <Title text={content.blogNoData} />
        </Styled.Main>
      </Layout>
    )
  }

  const seeMorePosts = () => setPostsNumber(postsNumber + 3)

  const visiblePosts = posts.slice(0, postsNumber)

  const highlightedPost = {
    title: content.blogTitle,
    img: posts[0].img,
    text: posts[0].title,
  }

  return (
    <Layout  location={location}>
      <SEO title="Blog" description={content.blogSeoDescription} />
      <Styled.Container>
        <IntroWithSlider
          slides={[highlightedPost]}
          btn={'Ler mais'}
          btnUrl={posts[0].path}
          invertColors
          isBlogPage
        />

        <div className="posts">
          {visiblePosts.map((p) => (
            <BlogSnippet key={p.id} post={p} miniTitle={p.miniTitle} />
          ))}
        </div>

        {posts.length > postsNumber && (
          <button className="view-more blue" onClick={() => seeMorePosts()}>
            {content.blogViewMore}
          </button>
        )}
      </Styled.Container>
    </Layout>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            path
            title
            miniTitle
            img
            status
            description
          }
        }
      }
    }
    miscJson {
      blogSeoDescription
      blogTitle
      blogNoData
      blogViewMore
    }
  }
`

import React from 'react'
import { getDayAndMonth } from '../utils/helpers'
import styled from 'styled-components'
import { Link } from 'gatsby'

export const BlogSnippet = ({ miniTitle, post }) => {
  return (
    <StyledSnippet className="blog-snippet">
      <Link to={post.path}>
        <h3 className="mini-title">{miniTitle}</h3>
        <h2
          className="title black"
          dangerouslySetInnerHTML={{ __html: post.title }}
        />
        <div className="image">
          <div className="date bg-blue white">
            {getDayAndMonth(post.date).day}
            <hr />
            {getDayAndMonth(post.date).month}
          </div>
          <div className="img-wrapper">
            <img src={post.img} alt={post.title} />
            <div className="read-more bg-gold white">Ler Mais</div>
          </div>
        </div>
      </Link>
    </StyledSnippet>
  )
}

const StyledSnippet = styled.article`
  .title {
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-size: 5rem;
    line-height: 5.5rem;
    font-weight: ${({ theme }) => theme.fontRegular};
    max-width: 100rem;
    text-align: center;
    margin: ${({ theme }) => theme.spacingXS} auto 0 auto;
  }

  .image {
    position: relative;
    width: 65%;
    margin: ${({ theme }) => theme.spacingM} auto 0 auto;

    .img-wrapper {
      position: relative;
      overflow: hidden;
    }

    img {
      width: 100%;
      max-width: 100%;
    }

    .read-more {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.5rem;
      text-transform: uppercase;
      border-top-left-radius: 70%;
      border-top-right-radius: 70%;
      transform: translateY(101%);
      transition: transform 0.25s ease;
    }

    &:hover .read-more {
      transform: translateY(0);
    }
  }

  .date {
    position: absolute;
    top: -3rem;
    left: -1.5rem;
    padding: ${({ theme }) => `2rem ${theme.spacingXS}`};
    font-size: 2.4rem;
    line-height: 3.6rem;
    font-weight: ${({ theme }) => theme.fontRegular};
    text-align: center;
    border-radius: 50px;
    z-index: 11;

    hr {
      min-width: 5rem;
    }
  }

  @media screen and (max-width: 1000px) {
    .image {
      margin-top: 12rem;
      width: 100%;
    }

    .date {
      left: 50%;
      transform: translateX(-50%);
      top: -${({ theme }) => theme.spacingM};
    }
  }
`

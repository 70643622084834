import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacingXL};

  .posts {
    margin-top: 10vh;

    .blog-snippet:not(:first-child) {
      margin-top: ${({ theme }) => theme.spacingXL};
    }

    @media (max-width: 1000px){
        margin-top: 5rem !important;
    }
  }

  .background{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: flex-end;
    background-color: transparent;
    left: auto;
    bottom: auto;
    right: auto;
    transform: none;
  
  }

  .view-more {
    display: block;
    background: transparent;
    font-size: 2.1rem;
    font-weight: ${({ theme }) => theme.fontBold};
    margin: ${({ theme }) => `${theme.spacingL} auto 0 auto`};
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
  }

  @media (max-width: 1000px){
    .content{
      max-width: 90% !important;
    }
    .blog-snippet:not(:first-child) {
      margin-top: 15vw !important;
    }
  }
 
`

export const Styled = {
  Container,
}

import React from 'react'
import styled from 'styled-components'

export const Title = ({ text, dark, className }) => (
  <StyledTitle
    className={className ? `title ${className}` : 'title'}
    dark={dark}
    dangerouslySetInnerHTML={{ __html: text }}
  />
)

const StyledTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontFamilySecondary};
  font-size: 5rem;
  line-height: 5rem;
  font-weight: ${({ theme }) => theme.fontRegular};
  color: ${(props) => (props.dark ? props.theme.black : props.theme.white)};
`

import React, { useState } from 'react'
import { CTA } from './CTA'
import PlayIcon from '../assets/images/play.svg'
import styled from 'styled-components'

export const IntroWithSlider = ({
  slides,
  btn,
  btnUrl,
  separator,
  invertColors,
  isBlogPage,
}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const slide = slides[activeIndex]

  return (
    <>
      <StyledRow
        image={slide.img}
        invertColors={invertColors}
        isBlogPage={isBlogPage}
      >
        <div className="background">
          <div className="content white">
            <h1 dangerouslySetInnerHTML={{ __html: slide.title }} />
            <p dangerouslySetInnerHTML={{ __html: slide.text }} />
            <CTA
              text={btn}
              url={btnUrl}
              color={invertColors ? 'gold' : 'blue'}
              className="to-white"
            />
            {slide.video && (
              <a href={slide.video} target="_blank" rel="noopener">
                <div className="video-cta">
                  <PlayIcon /> Ver vídeo
                </div>
              </a>
            )}
          </div>
        </div>

        <div className="image"></div>

        {slides.length > 1 &&
          (activeIndex < slides.length - 1 ? (
            <span
              onClick={() => setActiveIndex(activeIndex + 1)}
              className="arrow white"
            >
              {'>'}
            </span>
          ) : (
            <span
              onClick={() => setActiveIndex(activeIndex - 1)}
              className="arrow white"
            >
              {'<'}
            </span>
          ))}
      </StyledRow>

      {separator && (
        <StyledSeparator invertColors={invertColors} className="white">
          <h2>{separator}</h2>
        </StyledSeparator>
      )}
    </>
  )
}

const StyledRow = styled.section`
  position: relative;
  display: flex;
  min-height: 100vh;
  overflow: ${(props) => (props.isBlogPage ? 'unset' : 'hidden')};

  .background {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: flex-end;
    background-color: transparent;
    left: auto;
    bottom: auto;
    right: auto;
    transform: none;
    top: auto;
    padding: ${({ theme }) => theme.spacingS};
  
  }

  .content {
    max-width: 100%;
    position: absolute;
    top: 50vh;
    right: auto;
    text-align: center;
    font-size: 1rem;
    left: auto;
    width: 100%;

    h1 {
      font-family: ${({ theme }) => theme.fontFamilySecondary};
      font-size: 6.9em;
      line-height: 1em;
      font-weight: ${({ theme }) => theme.fontRegular};
    }

    p {
      font-size: 3em;
      line-height: 1.3em;
      font-weight: ${({ theme }) => theme.fontMedium};
      margin-top: ${({ theme }) => theme.spacingM};
      margin-left: auto;
      margin-right: auto;
      max-width: 45rem;
      text-transform: ${(props) => (props.isBlogPage ? 'uppercase' : 'unset')};
    }

    .cta {
      min-width: 20rem;
      margin-top: ${({ theme }) => theme.spacingL};
      font-size: 2em;
    }

    .video-cta {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      text-transform: uppercase;
      margin-top: ${({ theme }) => theme.spacingM};

      svg {
        width: 5rem;
        margin-right: ${({ theme }) => theme.spacingXS};
      }
    }

    @media screen and (max-width: 1550px) {
      right: auto;
      font-size: 0.85rem;

      p {
        margin-top: ${({ theme }) => theme.spacingS};
      }
      .cta {
        margin-top: ${({ theme }) => theme.spacingM};
      }
    }

  }

  .image {
    width: 100%;
    margin-left: auto;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center;

  }

  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacingS};
    font-size: 8.2rem;
    font-weight: ${({ theme }) => theme.fontSemiBold};
    cursor: pointer;
  }


  @media screen and (max-width: 1000px) {
   
    > div {
      width: 100%;
    }


    .image {
      background-position: center;
    }

    .arrow {
      transform: unset;
      top: unset;
      bottom: 30rem;
    }

  
  }

  @media screen and (max-width: 450px) {
    .content {
      h1 {
        font-size: 4rem;
      }

      p {
        font-size: 1.8rem;
      }
    }
  }
`

const StyledSeparator = styled.div`
  padding: ${({ theme }) => theme.spacingXXS};
  text-align: center;
  background-color: ${(props) =>
    props.invertColors ? props.theme.gold : props.theme.blue};

  h2 {
    font-family: ${({ theme }) => theme.fontFamilySecondary};
    font-size: 5rem;
    line-height: 7rem;
    font-weight: ${({ theme }) => theme.fontRegular};
  }

  @media screen and (max-width: 1000px) {
    display: none;
  }
`
